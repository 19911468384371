import React, {
  FunctionComponent,
  useState,
  useEffect,
  useRef,
} from 'react';
import Img from 'gatsby-image';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPause,
  faPlay,
  faUndo,
  faForward,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { CSSTransition } from 'react-transition-group';
import classnames from 'classnames';
import { Link } from 'gatsby';

import { Podcast } from '../types';

interface Props {
  activePodcastId: string;
  expandable?: boolean;
  podcast: Podcast;
  onPodcastPlayStateChanged: (id: string) => void;
}

const PodcastCardFull: FunctionComponent<Props> = (props: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [timeElapsed, setTimeElapsed] = useState('00:00:00');
  const [duration, setDuration] = useState(0);
  const [seekPosition, setSeekPosition] = useState(0);
  const [showContent, setShowContent] = useState(false);

  const {
    activePodcastId,
    expandable = false,
    podcast,
    onPodcastPlayStateChanged,
  } = props;

  const playerRef = useRef<HTMLAudioElement | null>(null);

  function formatTime(time: number) {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time - (hours * 3600)) / 60);
    const seconds = Math.floor(time - (hours * 3600) - (minutes * 60));

    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  }

  useEffect(() => {
    function onSeek(event: Event) {
      const target = event.target as HTMLAudioElement;

      setSeekPosition((target.currentTime / target.duration) * 100);
      setTimeElapsed(formatTime(target.currentTime));
    }

    const player = playerRef.current;

    if (player) {
      player.addEventListener('timeupdate', onSeek);
    }

    return () => {
      if (player) {
        player.removeEventListener('timeupdate', onSeek);
      }
    };
  }, []);

  useEffect(() => {
    if (playerRef.current && isPlaying && activePodcastId !== podcast.id) {
      playerRef.current.pause();

      setIsPlaying(false);
    }
  }, [activePodcastId, podcast, isPlaying]);

  function formatDate(date: Date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  }

  function onClick() {
    if (playerRef.current) {
      setIsPlaying(!isPlaying);

      if (isPlaying) {
        playerRef.current.pause();

        onPodcastPlayStateChanged('');
      } else {
        playerRef.current.play();

        onPodcastPlayStateChanged(podcast.id);
      }
    }
  }

  return (
    <div className="podcast-card-full">
      <div className="podcast-card-full__header">
        <Link to={`/podcasts/episode/${podcast.slug}`} className="header-image">
          {podcast.primaryImage ? (
            <Img fluid={podcast.primaryImage.fluid} className="podcast-card-full__image" />
          ) : null}
        </Link>

        <div className="header-title">
          <p className="podcast-card-full__date">{formatDate(new Date(podcast.publishedDate))}</p>

          <Link to={`/podcasts/episode/${podcast.slug}`}>
            <p className="podcast-card-full__title">{podcast.title}</p>
          </Link>

          {/* eslint-disable jsx-a11y/media-has-caption */}
          {podcast.audioUrl ? (
            <>
              <audio controls style={{ display: 'none' }} ref={playerRef} onCanPlay={() => setDuration(playerRef.current?.duration || 0)}>
                <source src={podcast.audioUrl} type="audio/mpeg" />
              </audio>

              <div className="controls">
                <FontAwesomeIcon
                  icon={faUndo}
                  className="controls__icon"
                  title="Rewind 10 Seconds"
                  onClick={() => {
                    if (playerRef.current) {
                      playerRef.current.currentTime -= 10;
                    }
                  }}
                />

                {isPlaying ? (
                  <FontAwesomeIcon
                    icon={faPause}
                    className="controls__icon"
                    title="Pause"
                    onClick={onClick}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faPlay}
                    className="controls__icon"
                    title="Play"
                    onClick={onClick}
                  />
                )}

                <FontAwesomeIcon
                  icon={faForward}
                  className="controls__icon"
                  title="Fast Forward"
                  onClick={() => {
                    if (playerRef.current) {
                      if (playerRef.current.playbackRate === 2) {
                        playerRef.current.playbackRate = 1;
                      } else {
                        playerRef.current.playbackRate = 2;
                      }
                    }
                  }}
                />
              </div>

              <div className="podcast-card-full__seek-track">
                <p>{timeElapsed}</p>

                <div className="duration">
                  <div className="progress" style={{ width: `${seekPosition}%` }} />
                </div>

                <p>{formatTime(duration)}</p>
              </div>
            </>
          ) : null}
        </div>

        {expandable ? (
          <FontAwesomeIcon
            icon={faPlusCircle}
            className={classnames('expand-icon', { 'expand-icon--active': showContent })}
            onClick={() => setShowContent(!showContent)}
          />
        ) : null}
      </div>

      <CSSTransition in={showContent} timeout={300} classNames="slide-down-podcast">
        <div className="slide-init">
          <div className="podcast-card-full__content">
            {podcast.content ? (
              documentToReactComponents(podcast.content.json)
            ) : null}
          </div>

          <div className="podcast-card-full__footer text-center">
            <Link to={`/podcasts/episode/${podcast.slug}`} className="button">Read More</Link>
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

export default PodcastCardFull;
